export function setWithExpiry(key, value, ttl) {
	const now = new Date()
	const item = {
		value: value,
		expiry: now.getTime() + (ttl * 1000),
	}
	sessionStorage.setItem(`bhmm.${key}`, JSON.stringify(item))
}

export function getWithExpiry(key) {
	const itemStr = sessionStorage.getItem(`bhmm.${key}`)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		sessionStorage.removeItem(`bhmm.${key}`)
		return null
	}
	return item.value
}

export function removeItem(key) {
  sessionStorage.removeItem(`bhmm.${key}`)
  return true
}

export function setItem(key, value) {
  const item = {
		value: value
	}
  sessionStorage.setItem(`bhmm.${key}`, JSON.stringify(item))
}

export function getItem(key) {
  const itemStr = sessionStorage.getItem(`bhmm.${key}`)
	if (!itemStr) {
		return null
	}
  const item = JSON.parse(itemStr)
  return item.value
}