import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SetPasswordCard } from '../components'
import { resetPassword } from '../utils/requestHelper'
import useWindowDimensions from '../hooks/useWindowDimensions'

import background from '../images/background.jpg'
import { getItem, removeItem } from '../utils/storageHelper'

const SetPassword = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [ loading, setLoading] = useState(false);

  useEffect(()=>{
    var resetObject = getItem("pwReset")
    if(!resetObject)
      navigate('/verifytoken')
  },[])

  const style = {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundAttachment: 'fixed',
      backgroundColor: 'rgb(49, 52, 67)',
      height: '100vh',
      overflow: 'hidden'
    },
    mainMobile: {
      minHeight: '100vh',
      display: 'flex',
      width: '100%',
      backgroundColor: 'rgb(255, 255, 255)'
    }
  }

  const handleOnClickResetPassword = async (password) => {
    setLoading(true)
    var user = getItem("pwReset")
    if(password && user.guid && user.token && user.mail){
      var result = await resetPassword({guid: user.guid, token:user.token, mail: user.mail, password: password.password})
      if(result === "success")
        removeItem("pwReset")
        navigate('/')
    }
    setLoading(false)
  }

  return (
    <main>
      <div style={width > 768 ? style.main : style.mainMobile}>
        <SetPasswordCard
          headline={t('headline-new_password')}
          subline={t('subline-new_password')}
          labelPassword={t('input-label-new_password')}
          labelConfirmPassword={t('input-label-confirm_password')}
          buttonText={t('button-submit')}
          onButtonClick={handleOnClickResetPassword}
          loading={loading}
        />
      </div>
    </main>
  );
}

export default SetPassword;