import axios from 'axios';

const auth = new Buffer.from(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")

export const signin = async (object) => {
  try{
    var data = JSON.stringify({
      "mail": object.mail,
      "password": object.password,
      "clientId": object.clientId,
    });
    
    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/auth/signin`,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 500) {
        throw new Error(err.response.statusText);
      }
      if (err.response.status === 401) {
        return {data: err.response.data }
      }
    });
    return result.data
  }
  catch (err) {
    return err
  }
}

export const initPasswordReset = async (mail) => {
  try{
    var config = {
      method: 'get',
      url: `${window._env_.REACT_APP_API_URL}/auth/authorize/reset/${mail}`,
      headers: { 
        Authorization: 'Basic ' + auth
      }
    };
    var result = await axios(config).catch(err => {
      if (err.response.status === 500) {
        throw new Error(err.response.statusText);
      }
    });
    if(result.status === 204)
      return "success"
    else
      return "error"
  }
  catch (err) {
    return err
  }
}

export const verifyResetToken = async (object) => {
  try{
    var data = JSON.stringify({
      "token": object.token
    });
    var config = {
      method: 'post',
      url: `${window._env_.REACT_APP_API_URL}/auth/authorize/reset/${object.mail}`,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config).catch(err => {
      throw new Error(err.response.statusText);
    });
    return result.data
  }
  catch (err) {
    return err
  }
}

export const resetPassword = async (object) => {
  try{
    var data = JSON.stringify({
      "token": object.token,
      "guid": object.guid,
      "password": object.password
    });
    var config = {
      method: 'patch',
      url: `${window._env_.REACT_APP_API_URL}/auth/authorize/reset/${object.mail}`,
      headers: { 
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/json'
      },
      data : data
    };
    var result = await axios(config).catch(err => {
      throw new Error(err.response.statusText);
    });
    if(result.status === 204)
      return "success"
    else
      return "error"
  }
  catch (err) {
    return err
  }
}