import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordCard } from '../components'
import { initPasswordReset } from '../utils/requestHelper'
import useWindowDimensions from '../hooks/useWindowDimensions'

import background from '../images/background.jpg'

const ResetPassword = () => {
  const {t} = useTranslation();
  const { width } = useWindowDimensions();
  const [ loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const style = {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundAttachment: 'fixed',
      backgroundColor: 'rgb(49, 52, 67)',
      height: '100vh',
      overflow: 'hidden'
    },
    mainMobile: {
      minHeight: '100vh',
      display: 'flex',
      width: '100%',
      backgroundColor: 'rgb(255, 255, 255)'
    }
  }

  const handleOnClickGetResetToken = async (mail) => {
    setLoading(true)
    var result = await initPasswordReset(mail)
    if(result === "success")
      navigate('/verifytoken')
    setLoading(false)
  }

  return (
    <main>
      <div style={width > 768 ? style.main : style.mainMobile}>
        <ResetPasswordCard
          headline={t('headline-forgot_password')}
          subline={t('subline-forgot_password')}
          labelMail={t('input-label-mail')}
          buttonText={t('button-submit')}
          linkText={t('button-verificytion')}
          linkMeta={t('label-verificytion')}
          onButtonClick={handleOnClickGetResetToken}
          loading={loading}
        />
      </div>
    </main>
  );
}

export default ResetPassword;