import logo from '../images/logo.svg'
import background from '../images/background.jpg'

const style = {
  loginWrapper: {
    width: 480
  },
  loginWrapperMobile: {
    width: '100%'
  },
  login: {
    margin: '1em auto',
    textAlign: 'center',
    height: 'auto',
    width: '100%',
    backgroundColor: 'rgb(255, 255, 255)'
  },
  loginMobile: {
    textAlign: 'center',
    height: 'auto',
    width: '100%',
    backgroundColor: 'rgb(255, 255, 255)'
  },
  loginHeader: {
    backgroundColor: '#000',
    backgroundImage: 'none',
  },
  loginHeaderMobile: {
    width: '100%',
    height: 192,
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'rgb(49, 52, 67)',
    backgroundPosition: 'center top 25%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`,
  },
  logo: {
    margin: 'auto',
    paddingTop: 70,
    paddingBottom: 60,
    width: 300
  }
}

export default style