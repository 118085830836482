import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Login, ResetPassword, VerifyToken, SetPassword } from './pages'

const Router = () => {
  return (
    <Routes>
      <Route path='/resetpassword' element={<ResetPassword />} />
      <Route path='/verifytoken' element={<VerifyToken />} />
      <Route path='/setpassword' element={<SetPassword />} />
      <Route exact path='/' element={<Login />} />
      {/* Not Found */}
      <Route
        path="*"
        element={<Login />}
      />
    </Routes>
  );
};

export default Router;