import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Header, Form, Button, Image } from 'semantic-ui-react'
import { InputField } from '..'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import logo from '../../images/logo.svg'
import style from '../../styles'

const LoginCard = (props) => {
  const { width } = useWindowDimensions();
  const [ data, setData ] = useState({mail: null, password: null})

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnSubmit()
    }
  }

  const handleOnSubmit = () => {
    if(data.mail && data.password)
      props.onClickSignin(data)
  }

  return(
    <div style={width > 768 ? style.loginWrapper : style.loginWrapperMobile}>
      <div style={width > 768 ? style.login : style.loginMobile}>
        <div style={width > 768 ? style.loginHeader : style.loginHeaderMobile}>
          <Image src={logo} style={style.logo} />
        </div>
        <Header style={{fontFamily: 'Futura', fontSize: '2rem', fontWeight: '300'}} as='h3'>{props.headline}</Header>
        <div style={{ width: '80%', margin: 'auto', display: 'inline-block', marginBottom: 40}}>
          <Form loading={props.loading} >
            <Form.Group>
              <InputField 
                handleOnChange={handleOnChange}
                value={data.mail}
                valueError = {props.error}
                id="mail"
                type="mail"
                label={props.labelMail}
                required
                isValid
              />
            </Form.Group>
            <Form.Group>
              <InputField 
                handleOnChange={handleOnChange}
                onKeyDown={handleKeyDown}
                value={data.password}
                valueError = {props.error}
                id="password"
                type="password"
                label={props.labelPassword}
                required
              />
            </Form.Group>
          </Form>
          <div style={{textAlign: 'left'}}>
            <Button size='large' secondary onClick={handleOnSubmit} >{props.buttonText}</Button>
            <div style={{marginTop: 25}}>
              <Header as={Link} to={{ pathname: '/resetpassword', state : { mode: 'requestpw' }}} style={{fontFamily: 'Futura', fontSize: '1.5rem', fontWeight: '300'}}>
                {props.linkText}
                <Header.Subheader>
                  {props.linkMeta}
                </Header.Subheader>
              </Header>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginCard