import React, { useState } from 'react'
import { Header, Form, Button, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { InputField } from '..'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import logo from '../../images/logo.svg'
import style from '../../styles'

const SetPasswordCard = (props) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation()
  const [ data, setData ] = useState({password: null, confirmPassword: null})
  const [ error, setError ] = useState()

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSubmit = () => {
    if(data.password && data.confirmPassword)
      if(data.password === data.confirmPassword)
        props.onButtonClick(data)
      else
        setError(t('label-error-wrong_password'))
  }

  return(
    <div style={width > 768 ? style.loginWrapper : style.loginWrapperMobile}>
      <div style={width > 768 ? style.login : style.loginMobile}>
        <div style={width > 768 ? style.loginHeader : style.loginHeaderMobile}>
          <Image src={logo} style={style.logo} />
        </div>
        <Header style={{fontFamily: 'Futura', fontSize: '3rem', fontWeight: '300'}} as='h1'>{props.headline}</Header>
        <Header style={{fontFamily: 'Futura', fontSize: '2rem', fontWeight: '300'}} as='h3'>{props.subline}</Header>
        <div style={{ width: '80%', margin: 'auto', display: 'inline-block', marginBottom: 40}}>
          <Form loading={props.loading}>
            <Form.Group>
              <InputField 
                handleOnChange={handleOnChange}
                value={data.password}
                valueError={error}
                id="password"
                type="password"
                label={props.labelPassword}
                required
              />
            </Form.Group>
            <Form.Group>
              <InputField 
                handleOnChange={handleOnChange}
                value={data.confirmPassword}
                valueError={error}
                id="confirmPassword"
                type="password"
                label={props.labelConfirmPassword}
                required
              />
            </Form.Group>
          </Form>
          <div style={{textAlign: 'left'}}>
            <Button size='large' secondary onClick={handleOnSubmit} >{props.buttonText}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetPasswordCard