import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getItem, removeItem } from '../utils/storageHelper'
import { LoginCard } from '../components'
import { signin } from '../utils/requestHelper'
import useWindowDimensions from '../hooks/useWindowDimensions'

import background from '../images/background.jpg'

const Login = () => {
  const {t} = useTranslation();
  const { width } = useWindowDimensions();
  const [ loading, setLoading] = useState(false);
  const [ error, setError ] = useState();

  const style = {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundAttachment: 'fixed',
      backgroundColor: 'rgb(49, 52, 67)',
      height: '100vh',
      overflow: 'hidden'
    },
    mainMobile: {
      minHeight: '100vh',
      display: 'flex',
      width: '100%',
      backgroundColor: 'rgb(255, 255, 255)'
    }
  }

  const handleOnClickSignin = async (data) => {
    setLoading(true)
    var appId = getItem("appID")
    var result = await signin({...data, clientId: appId})
    if(result.error)
      setError(t('input-error-invailid'))
    else{
      let url = getItem("redirectURL")
      removeItem("redirectURL")
      removeItem("appID")
      window.location = (`${url}?accessToken=${result.accessToken}&refreshToken=${result.refreshToken}${result.externalToken ? '&externalToken' + result.externalToken : ''}`)
    }
    setLoading(false)
  }

  return (
    <main>
      <div style={width > 768 ? style.main : style.mainMobile}>
        <LoginCard
          headline={t('subline-login')}
          labelMail={t('input-label-mail')}
          labelPassword={t('input-label-password')}
          buttonText={t('button-login')}
          linkText={t('button-forget_password')}
          linkMeta={t('label-forget_password')}
          onClickSignin={handleOnClickSignin}
          loading={loading}
          error={error}
        />
      </div>
    </main>
  );
}

export default Login;