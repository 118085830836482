import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VerifyTokenCard } from '../components'
import { verifyResetToken } from '../utils/requestHelper'
import useWindowDimensions from '../hooks/useWindowDimensions'

import background from '../images/background.jpg'
import { setItem } from '../utils/storageHelper'

const VerifyToken = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const { width } = useWindowDimensions();
  const [ loading, setLoading] = useState(false);

  const style = {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      backgroundPositionY: 'center',
      backgroundAttachment: 'fixed',
      backgroundColor: 'rgb(49, 52, 67)',
      height: '100vh',
      overflow: 'hidden'
    },
    mainMobile: {
      minHeight: '100vh',
      display: 'flex',
      width: '100%',
      backgroundColor: 'rgb(255, 255, 255)'
    }
  }

  const handleOnClickVerifyResetToken = async (data) => {
    setLoading(true)
    var result = await verifyResetToken(data)
    if(result.guid){
      setItem("pwReset", {guid: result.guid, token: data.token, mail: data.mail})
      navigate('/setpassword')
    }
    setLoading(false)
  }

  return (
    <main>
      <div style={width > 768 ? style.main : style.mainMobile}>
        <VerifyTokenCard
          headline={t('headline-password_code')}
          subline={t('subline-password_code')}
          labelMail={t('input-label-mail')}
          labelResetCode={t('input-label-password_code')}
          buttonText={t('button-submit')}
          onButtonClick={handleOnClickVerifyResetToken}
          loading={loading}
        />
      </div>
    </main>
  );
}

export default VerifyToken;