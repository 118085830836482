import React, { useEffect } from 'react';
import Router from './router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { setItem } from './utils/storageHelper'

import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import './fonts/index.css';

function App() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(()=>{
    if(location.search) {
      var redirectURL = searchParams.get("redirectURL")
      var appID = searchParams.get("appID")
      setItem("redirectURL",redirectURL)
      setItem("appID",appID)
    }
  },[location])

  return (
    <Router />
  );
}

export default App;
